import IWinnerDashboard from '../../interfaces/winner-dashboard.interface';

export default class WinnerDashboard implements IWinnerDashboard {
  private id: string;

  public name: string;

  public value: number;

  constructor(data: any = {}) {
    this.id = data.user_id || '';
    this.name = data.user_name || 'Sem dados';
    this.value = data.value || 0;
  }

  contentIsNull(): boolean {
    return !this.id;
  }
}


import { Component, Vue, Prop } from 'vue-property-decorator';
import WinnerDashboard from '../entities/ranking/winner-dashboard.entity';


@Component
export default class WinnerRegisterClientsByUnitChart extends Vue {
  @Prop({ type: Boolean, default: false })
  private active!: boolean;

  @Prop({ type: Object, default: () => ({}) })
  private content!: WinnerDashboard;

  private get color() {
    return this.active ? 'primary' : '';
  }
}
